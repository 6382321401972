<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1200px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Needs Of {{ volunteerName }}
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text
        class="d-flex justify-center flex-column"
        style="font-family:'Poppins-Medium';color: #1e1e1e"
      >
        <v-data-table
          :headers="headers"
          :items="volunteerWorks"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title
                >Total Works Hours:
                <span class="hrs">{{ totalHours }}</span></v-toolbar-title
              >
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-text v-if="message">
        <v-alert dense type="error">{{ message }}</v-alert>
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn color="#e84023" @click="onClose" class="white--text" width="150">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    volunteer: {
      type: Object
    },
    volunteerId: {
      type: String
    },
    selectedVolunteer: {
      type: String
    },
    onClose: {
      type: Function
    },
    totalHours: {
      type: String
    }
  },
  data() {
    return {
      message: null,
      loading: false,
      volunteerName: "",
      profile: {},
      volunteerWorks: [],
      headers: [
        // { text: 'Name', value: 'name' },
        // { text: 'Email', value: 'email' },
        // { text: 'Phone', value: 'phone' },
        // { text: 'Phone', value: 'phone' },
        { text: "Needs", value: "needs_title" },
        { text: "Minutes", value: "min" }
        // { text: 'Seconds', value: 'seconds' },
      ]
    };
  },
  methods: {
    ...mapActions("volunteer", {
      viewVolunteer: "viewVolunteer"
    }),
    // async fetchVolunteerData() {
    //   this.loading = true;
    //   try {
    //     const formData = new FormData();
    //     formData.append("user_id", this.selectedVolunteer);
    //     const response = await this.viewVolunteer(formData);

    //     // Accessing user data from the response
    //     this.profile = response.user || {};
    //     this.volunteerName = `${this.profile.first_name} ${this.profile.last_name}`;

    //      const uniqueNeeds = Array.from(new Set(this.profile.needs.map(need => need.need_id.title)));

    //     // Prepare the volunteerWorks array with minutes and hours inside needs
    //     this.volunteerWorks = this.profile.needs.map(need => ({
    //       name: `${this.profile.first_name} ${this.profile.last_name}`,
    //       email: this.profile.email,
    //       phone: this.profile.phone,
    //       needs_title: need.need_id.title,
    //       min: need.minutes,
    //       seconds: need.shift_id.hours,
    //     }));

    //   } catch (error) {
    //     this.message = "Failed to fetch volunteer data.";
    //     console.error("Error fetching volunteer data:", error);
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    async fetchVolunteerData() {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append("user_id", this.selectedVolunteer);
        const response = await this.viewVolunteer(formData);

        // Accessing user data from the response
        this.profile = response.user || {};
        this.volunteerName = `${this.profile.first_name} ${this.profile.last_name}`;

        // Create a map to aggregate minutes for each unique need title
        const needsMap = this.profile.needs.reduce((acc, need) => {
          const title = need.need_id.title;
          const minutes = need.minutes;
          // const seconds = need.shift_id.hours;

          if (!acc[title]) {
            acc[title] = { totalMinutes: 0, title: title };
            // acc[title] = { totalSeconds: 0, title: title };
          }
          acc[title].totalMinutes += minutes;
          // acc[title].totalSeconds += seconds;

          return acc;
        }, {});

        // Prepare the volunteerWorks array using aggregated data
        this.volunteerWorks = Object.values(needsMap).map(need => ({
          name: this.volunteerName,
          email: this.profile.email,
          phone: this.profile.phone,
          needs_title: need.title,
          min: need.totalMinutes
          // seconds: need.totalSeconds,
        }));
      } catch (error) {
        this.message = "Failed to fetch volunteer data.";
        console.error("Error fetching volunteer data:", error);
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    volunteer(newValue) {
      this.profile = { ...newValue };
    }
  },
  mounted() {
    console.log(this.selectedVolunteer, "Volunteer ID");
    this.fetchVolunteerData();
  }
};
</script>

<style scoped>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
.hrs {
  color: green !important;
}
</style>
