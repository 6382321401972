<template>
  <div class="w-100 px-2 mt-5">
    <v-container fluid class="pt-0 pb-15 d-flex flex-column">
      <span class="white--text font-30 app-medium-font ml-5 mb-3">{{
        $t("volunteers")
      }}</span>
      <v-data-table
        :headers="headers"
        :items="volunteers"
        :items-per-page="15"
        :loading="loading"
        @click:row="onSelectClient"
        class="elevation-0"
      >
        <template v-slot:[`item.image`]="{ item }">
          <div
            @click="goToProfile(item)"
            style="width: 200px;"
            class="d-flex flex-column align-center mt-3"
          >
            <v-img
              :src="
                item.bgImage
                  ? item.bgImage
                  : require('@/assets/background0.png')
              "
              width="200"
              style="flex-grow: 1"
              :aspect-ratio="
                $vuetify.breakpoint.smAndDown ? 414 / 180 : 1654 / 455
              "
              class="rounded-lg rounded-b-0 position-absolute"
            />
            <editable-avatar
              :image="item.thumbnail ? item.thumbnail : item.image"
              :editable="false"
              :size="50"
              class="mt-5"
            />
          </div>
          <div class="url-button app-bold-font" @click="goToProfile(item)">
            {{ item.first_name }} {{ item.last_name }}
          </div>
          <div class="font-12 mb-1">
            {{ formatPhoneNumber(item.phone) }}
            <v-tooltip bottom v-if="item.phone_verified">
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="14" class="ml-1" v-bind="attrs" v-on="on"
                  >mdi-check-circle</v-icon
                >
              </template>
              Phone verified
            </v-tooltip>
          </div>
          <div class="font-12 mb-1">
            {{ item.email }}
            <v-tooltip bottom v-if="item.email_verified">
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="14" class="ml-1" v-bind="attrs" v-on="on"
                  >mdi-check-circle</v-icon
                >
              </template>
              Email verified
            </v-tooltip>
          </div>
          <div class="font-12 mb-3">
            <div>
              {{ item.address
              }}{{ item.address2 ? ", " + item.address2 + "" : "" }}
            </div>
            <div>{{ item.city }}, {{ item.state }} {{ item.zip }}</div>
          </div>
        </template>
        <template v-slot:[`item.info`]="{ item }">
          <div class="ml-3" style="max-width: 250px;">
            <span class="app-bold-font font-12">Causes</span>
            <div class="d-flex flex-wrap">
              <v-chip
                v-for="(icon, key) in item.causes"
                :key="key"
                class="mr-1 mt-2"
                x-small
                >{{ icon }}</v-chip
              >
            </div>
          </div>
          <div class="ml-3 my-3" style="max-width: 250px;">
            <span class="app-bold-font font-12 mt-3">Interests</span>
            <div class="d-flex flex-wrap">
              <v-chip
                v-for="(icon, key) in item.interests"
                :key="key"
                class="mr-1 mt-2"
                x-small
                >{{ icon }}</v-chip
              >
            </div>
          </div>
        </template>
        <template v-slot:[`item.spent_hours`]="{ item }">
          <div class="ml-3" style="max-width: 250px;">
            <v-tooltip bottom v-if="item.volunteered_hours > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  color="#0A5B8A"
                  size="14"
                  @click="ondetailedView(item._id)"
                  >mdi-eye</v-icon
                >
              </template>
              <span>Detailed View</span>
            </v-tooltip>
            <span class="app-bold-font font-12">Total worked Hours :</span>
            <div class="">
              {{ item.spentHours }}
            </div>
          </div>
        </template>
        <template v-slot:[`item.availabilities`]="{ item }">
          <div class="d-flex mt-2 flex-column" v-if="item.availabilities">
            <div
              v-for="(availability, index) in item.availabilities.filter(
                a => a.morning || a.afternoon || a.evening
              )"
              :key="index"
              class="mb-3 d-flex align-center"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    color="#0A5B8A"
                    size="14"
                    >mdi-calendar-clock</v-icon
                  >
                </template>
                <span>Available time</span>
              </v-tooltip>
              <div class="app-bold-font font-12">
                {{ availability.day }}
              </div>
              <span class="ml-3 font-12">
                {{ getScheduleTime(availability) }}
              </span>
            </div>
          </div>
        </template>
        <template v-slot:[`item.about_me`]="{ item }">
          <div class="text-block">{{ item.about_me }}</div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <span class="app-bold-font font-12">Age group</span>
          <div class="d-flex align-center mb-2 font-12">
            <v-icon class="mr-2" color="#0A5B8A" size="14">
              mdi-account-group
            </v-icon>
            {{ item.age_group }}
          </div>
          <span class="app-bold-font font-12">Followers</span>
          <div class="d-flex flex-column mb-2 font-12">
            <span>{{ item.fanned.length }} Agencies</span>
            <span>{{ item.followers.length }} Volunteers</span>
          </div>
          <span class="app-bold-font font-12">Created At</span>
          <div class="font-12">{{ formatDate(item.createdAt) }}</div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                  small
                  class="ml-2"
                  @click.stop="onEditVolunteer(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </template>
              Edit
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                  small
                  class="ml-2"
                  @click.stop="onDeleteVolunteer(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              Delete
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :onClose="onCloseConfirmDelete"
        :onConfirm="onConfirmDelete"
      />
      <admin-edit-volunteer-dialog
        :dialog="editDialog"
        :onClose="onCloseEditDialog"
        :volunteer="selected"
        v-if="editDialog && selected"
      />
      <admin-view-volunteer-works-dialog
        :dialog="viewDialog"
        :onClose="onCloseViewDialog"
        :selectedVolunteer="selectedVolunteer"
        :totalHours="totalHours"
        v-if="viewDialog"
      />
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import dateFormat from "dateformat";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
import AdminEditVolunteerDialog from "./AdminEditVolunteerDialog.vue";
import AdminViewVolunteerWorksDialog from "./AdminViewVolunteerWorksDialog.vue";
export default {
  components: {
    EditableAvatar,
    ConfirmDeleteDialog,
    AdminEditVolunteerDialog,
    AdminViewVolunteerWorksDialog
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "", value: "image" },
        { text: "Info", value: "info" },
        { text: "About", value: "about_me" },
        { text: "Spent Hours", value: "spent_hours" },
        { text: "Availabilities", value: "availabilities" },
        { text: "Created At", value: "createdAt" },
        { text: "Actions", value: "actions" }
      ],
      deleteDialog: false,
      selected: null,
      expanded: [],
      editDialog: false,
      viewDialog: false
    };
  },

  methods: {
    ...mapActions("volunteer", {
      fetchAllVolunteers: "fetchAllVolunteers",
      deleteVolunteer: "deleteVolunteer"
    }),
    onSelectClient(item) {
      console.log(item, "item");
    },
    handleDialogOpen(volunteerId) {
      // Fetch volunteer and set selectedVolunteer here
      this.selectedVolunteer = volunteerId;
      this.viewDialog = true;
    },
    fetchVolunteerById(id) {
      // Implement your API call to fetch the volunteer by ID
      return this.$store.dispatch("volunteer/viewVolunteer", id);
    },
    handleDialogClose() {
      this.viewDialog = false;
    },
    formatDate(date) {
      return dateFormat(this.convertTZ(date, this.timezone), "mm/dd/yyyy");
    },

    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York"
          }
        )
      );
    },
    goToProfile(item) {
      this.$router.push({
        name: "volunteer-profile-details",
        params: { id: item._id }
      });
    },
    onEditVolunteer(item) {
      this.selected = item;
      this.editDialog = true;
    },
    onCloseEditDialog() {
      this.editDialog = false;
    },
    onCloseViewDialog() {
      this.viewDialog = false;
    },
    ondetailedView(volunteerId) {
      this.selectedVolunteer = volunteerId;
      this.totalHours = this.volunteers.find(
        volunteer => volunteer._id === volunteerId
      ).spentHours;
      this.viewDialog = true;
    },
    onDeleteVolunteer(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.deleteDialog = false;
      this.loading = true;
      this.deleteVolunteer({ _id: this.selected._id })
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    getDay(schedule) {
      return schedule.split(" ")[0];
    },
    getScheduleTime(schedule) {
      let str = schedule.morning ? "Morning" : "";
      str = !schedule.afternoon ? str : str ? str + ", Afternoon" : "Afternoon";
      str = !schedule.evening ? str : str ? str + ", Evening" : "Evening";

      return str;
    }
  },
  computed: {
    ...mapGetters({
      volunteers: "volunteer/getAllVolunteers",
      profile: "auth/getProfile",
      profileType: "auth/getType",
      timezone: "usersetting/getTimeZone"
    })
  },
  mounted() {
    if (this.profileType != "Admin") {
      this.$router.push({ name: "home" });
    }
    this.loading = true;
    this.fetchAllVolunteers()
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  }
};
</script>
